import styled from "styled-components";

const CenterRestriction = styled.div`
  margin: auto;
  max-width: 60%;
  @media(max-width: 1300px){
    max-width: 90% !important;
  }
`;
export default CenterRestriction;
