import React from "react";
import Navigation from "./Navigation";
import styled from "styled-components";
import CenterRestriction from "./CenterRestriction";
import { IoIosCall, IoIosMail } from "react-icons/io";

const Header = styled.header`
  border-bottom: 1px solid var(--color-primary-border);
  background-color: white;
  top: 0;
  width: 100vw;
  z-index: 2;
  position: sticky;
  margin-bottom: 30px;
`;

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;

  @media(max-width: 470px) {
    flex-direction: column;
  }
`;

const InfoHeader = styled.div`
  background-color: var(--color-primary);
  
  @media(max-width: 470px) {
    display: none;
  }
`;

const InfoText = styled.div`
  color: var(--color-primary-text);
  text-align: left;
  width: 33%;
  display: inline;
  padding: 16px 0;
  text-transform: uppercase;

  @media(max-width: 470px) {
    width: 100% !important;
    text-align: center;
  }
`;

const InfoMail = styled.a`
  text-decoration: none;
  color: var(--color-primary-text);
  text-align: right;
  width: 33%;
  display: inline;
  padding: 16px 0;
  
  &:hover {
    text-decoration: underline;
  }

  @media(max-width: 470px) {
    width: 100% !important;
    text-align: center;
  }
`;

const InfoPhone = styled.a`
  text-decoration: none;
  color: var(--color-primary-text);
  text-align: center;
  width: 33%;
  display: inline;
  padding: 16px 0;
  &:hover {
    text-decoration: underline;
  }

  @media(max-width: 470px) {
    width: 100% !important;
    text-align: center;
  }
`;

export default () => (
  <Header>
    <InfoHeader>
      <CenterRestriction>
        <Toolbar>
          <InfoText>
            Seit 1987 in der Finanzbranche
          </InfoText>
          <InfoPhone href="tel:+41787835524">
            <IoIosCall style={{ verticalAlign: "middle", marginTop: "-2px", marginRight: "5px" }} />+41 78 783 55 24
          </InfoPhone>
          <InfoMail href="mailto:info@stifani-finanz.ch">
            <IoIosMail style={{ verticalAlign: "middle", marginTop: "-2px", marginRight: "5px" }} />info@stifani-finanz.ch
          </InfoMail>
        </Toolbar>
      </CenterRestriction>
    </InfoHeader>
    <Navigation />
  </Header>
);
