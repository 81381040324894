import React from "react";
import styled from "styled-components";
import CenterRestriction from "./CenterRestriction";
import { IoIosMail, IoMdPhonePortrait, IoMdDocument } from "react-icons/io";

import mandatUrl from "../documents/Mandat_Stifani_Finanz_GmbH.pdf";

const Footer = styled.footer`
  border-top: 1px solid;
  border-color: var(--color-primary-border);
  width: 100%;
  margin-top: 5vh;
  position: absolute;
`;
const Font = styled.p`
  color: var(--color-nav);
  text-decoration: none;
  padding-right: 50px;
`;
const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  @media(max-width: 470px) {
    flex-direction: column !important;
  }
`;
const ToolbarInfo = styled.div`
  display: flex;
  flex-direction: row;
  @media(max-width: 560px) {
    flex-direction: column !important;
  }
`;
const InfoFooter = styled.footer`
  background-color: var(--color-secondary);
  display: block;
  width: 100%;
`;
const Copyright = styled.p`
  float: right;
  color: var(--color-primary-text);
`;
const Info = styled.p`
  color: var(--color-primary-text);
  margin-right: 15px;
  text-align:left;
  float:left;
  @media(max-width: 560px){
    margin-bottom: 0px !important;
    }
`;
const Link = styled.a`
  text-decoration: none;
  color: var(--color-primary-text);
`;
const PdfLink = styled.a`
  text-decoration: none;
  color: var(--color-nav);
`;

export default () => (
  <Footer>
    <CenterRestriction>
      <Toolbar>
        <Font>
          Stifani Finanz GmbH<br />
          Lättichstrasse 1<br />
          6340 Baar
        </Font>
        <Font>
          <IoMdPhonePortrait /> +41 78 783 55 24<br />
          <IoIosMail /> info@stifani-finanz.ch<br />
          <IoMdDocument /> <PdfLink href={mandatUrl} target="_blank"> Mandat Stifani Finanz GmbH (PDF)</PdfLink>
        </Font>
      </Toolbar>
    </CenterRestriction>
    <InfoFooter>
      <CenterRestriction >
        <ToolbarInfo>
          <Info>
            <Link href="/impressum/#impressum">Impressum</Link>
          </Info>
          <Info>
            <Link href="/impressum/#datenschutz">Datenschutzerklärung</Link>
          </Info>
          <Copyright>
            Copyright © 2022 Stifani Finanz GmbH
          </Copyright>
        </ToolbarInfo>
      </CenterRestriction>
    </InfoFooter>
  </Footer >
);
