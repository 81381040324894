import "./Layout.css";
import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import CenterRestriction from "./CenterRestriction";

export default ({ children }) => {
  return (
    <>
      <Header />
      <CenterRestriction>
        <main>{children}</main>
      </CenterRestriction>
      <Footer />
    </>
  )
};
