import { Link } from "gatsby";
import React, { useState } from 'react';
import styled, { css } from "styled-components";
import CenterRestriction from "./CenterRestriction";

import LogoUrl from "../images/logo/LogoStifaniFinanz.png";

type BooleanString = "true" | "false";

const activeNavItemStyle = css`
  border-top: 3px solid var(--color-primary);
  color: var(--color-primary) !important;
  font-weight: bold;
`;

const inactiveNavItemStyle = css`
  border-top: 3px solid transparent;
`;

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;

  @media(max-width: 830px) {
    flex-direction: column;
  }
`;

const Dropdown = styled.div`
  background-color: #fff;
  position: absolute;
  padding-top: 11px;
  border: 1px solid var(--color-primary-border);
  border-top: 3px solid var(--color-primary);
  top: 100%;
  right: 0;
  display: none;
  flex-direction: column;
  z-index: 3;
`;

const NavItems = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 0;
  margin-left: 20px;
`;
const NavItem = styled(Link) <{ active?: BooleanString; }>`
  padding: 25px 10px 10px 10px;
  margin-bottom: 5px;
  list-style-type: none;
  text-align: right;
  position: relative;
  display: inline-block;
  user-select: none;

  ${props =>
    props.active === "true" ?
      activeNavItemStyle :
      inactiveNavItemStyle
  }

  &:hover ${Dropdown} {
    display: flex;
  }
`;

const ImgLogo = styled.img``;

const DropdownItem = styled(Link)`
  background-color: white;
  min-width: 150px;
  padding: 12px 15px;
  z-index: 1;
`;

const menuItemList = [{
  id: "home",
  name: "Home",
  path: "/"
}, {
  id: "company",
  name: "Unternehmen",
  path: "/company",
  subItems: [{
    id: "aboutUs",
    name: "Über Uns",
    path: "/aboutUs"
  }, {
    id: "partners",
    name: "Unsere Partner",
    path: "/partners"
  }],
}, {
  id: "kmu",
  name: "KMU",
  path: "/kmu"
}, {
  id: "person",
  name: "Privatpersonen",
  path: "/person"
}, {
  id: "services",
  name: "Services",
  path: "/services",
}, {
  id: "contact",
  name: "Kontakt",
  path: "/contact"
}];

export default () => {
  const currentItem = menuItemList.find(
    item =>
      typeof window !== "undefined" &&
      (
        item.path === window.location.pathname ||
        (item.subItems || [])
          .find(
            subItem =>
              subItem.path === window.location.pathname
          )
      )
  );
  const [activeMenuItemName, setActiveMenuItemName] = useState<string>(currentItem?.name || "Home");

  return (
    <CenterRestriction>
      <Toolbar>
        <div>
          <Link to="/">
            <ImgLogo src={LogoUrl} alt="Logo" />
          </Link>
        </div>
        <NavItems>
          {menuItemList.map(item => (
            <NavItem
              key={item.id}
              className="dropdown"
              style={{
                textDecoration: "none",
                paddingTop: "15px",
                color: "var(--color-nav)"
              }}
              to={item.path}
              active={activeMenuItemName === item.name ? "true" : "false"}
              onClick={() => setActiveMenuItemName(item.name)}
            >
              {item.name}
              {
                item.subItems?.length > 0 &&
                <Dropdown>
                  {(item.subItems || []).map(subItem => (
                    <DropdownItem
                      key={subItem.id}
                      to={subItem.path}
                      style={{
                        textDecoration: "none",
                        paddingTop: "15px",
                        color: "var(--color-nav)"
                      }}
                    >
                      {subItem.name}
                    </DropdownItem>
                  ))}
                </Dropdown>
              }
            </NavItem>
          ))}
        </NavItems>
      </Toolbar>
    </CenterRestriction>
  );
};